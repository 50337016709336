import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import Row, { Col } from 'components/row';
import { Thumb } from 'components/image';
import Link from 'components/link';
import { HideTelephoneSeo } from 'components/hide-telephone';
import Counter from 'subsys/counter';
import { iconTypes } from 'components/icon';

import { ModalCallBack } from 'src-sites/bild/section-project/contact-to-developer';
import { getAskDevHeader } from 'src-sites/bild/section-project/render-common';

import translates from './advert-i18n.json';

import 'styles/base/containers/content-block/blocks/userAdvert/_view-ad.scss';

const bemClasses = new Bem('viewList');

@I18nHoc(translates)
class AdvertList extends Component {
  static propTypes = {
    params: PropTypes.shape({}),
    gtmProps: PropTypes.shape({}),
    content: PropTypes.arrayOf(PropTypes.shape({})),
    external: PropTypes.bool,
    view: PropTypes.string
  };

  render() {
    const { content, view } = this.props;

    if (!content) return null;

    const { i18n } = this;
    const pageUrl = get(this, 'props.params.location.pathname');

    return (
      <>
        {map(content, (data, key) => {
          const description = get(data, 'data.description');
          const name = get(data, 'name');
          const phone = get(data, 'data.phone');
          const url = get(data, 'data.url');
          const image = get(data, 'image');

          if (!name || !description || !image) return null;
          const discount = get(data, 'data.discount');
          const conversions = get(data, 'data.conversions');
          const lcId = get(data, 'data.lc_id');
          const lcName = get(data, 'lc_display_name');
          const lcTitleImage = get(data, 'lc_title_image');
          const developerEmail = get(data, 'first_seller_parent_email');
          const mainDeveloperAvatar = get(data, 'first_seller_parent_avatar');
          const isShowModalCallBack = developerEmail && (discount || conversions);
          const askDevHeader = getAskDevHeader.call(
            this, bemClasses, { title_image: { id: lcTitleImage }, display_name: lcName }
          );
          const modalBtnLabel = discount ? i18n('aboutDiscount') : i18n('aboutDetail');
          const modalBtnIcon = discount ? iconTypes.gift : iconTypes.phoneCalls;
          const counterEntity = { user_advert_id: data.id };

          if (get(data, 'data.lc_id')) counterEntity.project_id = +get(data, 'data.lc_id');
          if (get(data, 'data.dev_id')) counterEntity.developer_id = +get(data, 'data.dev_id');

          const telephone = phone ? (
            <Counter
              action="phoneClick"
              entity={counterEntity}
              mode="click"
              injectClickName="onClick"
            >
              <HideTelephoneSeo
                {...bemClasses('link', 'tel')}
                phone={phone}
                slicedNumber={4}
                btnLabel="xxxx"
                classMods={{
                  telAd: true
                }}
              />
            </Counter>
          ) : null;

          return (
            <Fragment key={key}>
              <Counter
                entity={counterEntity}
                mode="view"
                action="showUserAdvert"
              />
              <Row {...bemClasses('row', { ad: true, [view]: !!view })} mpColumn>
                <Col {...bemClasses('col', 'img')} allAuto allPr={0}>
                  <div {...bemClasses('imgContainer')}>
                    <div {...bemClasses('img')}>
                      <Thumb
                        {...bemClasses('imgPic')}
                        image={{ id: image }}
                      />
                    </div>
                  </div>
                </Col>
                <Col {...bemClasses('col', 'info')} allFlex allPr={0}>
                  {url &&
                    <Counter
                      injectClickName="onClick"
                      mode="click"
                      entity={counterEntity}
                      action="linkClick"
                    >
                      <Link
                        {...bemClasses('link', 'fill')}
                        href={url}
                        rel="nofollow"
                        external
                        blank
                        children={<span />}
                      />
                    </Counter>
                  }
                  <div {...bemClasses('text', 'name')} children={name} />
                  <div {...bemClasses('text', 'description')} children={description} />
                  {url && !isShowModalCallBack && <div {...bemClasses('text', 'more')} children={i18n('moreDetails')} />}
                  {isShowModalCallBack &&
                    <ModalCallBack
                      classes={bemClasses}
                      askDevHeader={askDevHeader}
                      projectId={lcId}
                      projectUrl={pageUrl}
                      developer={{ avatar: mainDeveloperAvatar }}
                      developerEmail={developerEmail}
                      modalBtnProps={{
                        label: modalBtnLabel,
                        title: modalBtnLabel,
                        inListDD: false,
                        iconType: modalBtnIcon
                      }}
                    />
                  }
                  {telephone}
                </Col>
              </Row>
            </Fragment>
          );
        })}
      </>
    );
  }
}

export default AdvertList;
